<template>
    <div class="setting-third">
        <div class="person-header">
            <p>
                <strong>第三方账号管理</strong>
            </p>
        </div>
        <div class="mb-20"></div>
        <div class="person-center">
          <div class="person-center-header">
            <i class="iconfont icon-tips"></i>
            <div class="mr-10"></div>
            <p class="f-12 h-57">
              温馨提示：温馨提示凑一下字数温馨提示凑一下字数，温馨提示凑一下字数，温馨提示凑一下字数温馨提示凑一下字数，温馨提示凑一下字数温馨提示凑一下字数温馨提示凑一下字数。
            </p>
          </div>
          <div class="mb-20"></div>
          <ul class="third-ul">
            <li>
              <p>
                <i class="iconfont icon-success"></i>
                <span>设置密码</span>
              </p>
              <span class="h-57 f-12">已设置</span>
              <span class="b">修改密码</span>
            </li>
            <li>
              <p>
                <i class="iconfont icon-success"></i>
                <span>实名认证</span>
              </p>
              <span class="h-57 f-12">已实名认证</span>
              <span class="b">查看详情</span>
            </li>
            <li>
              <p>
                <i class="iconfont icon-success"></i>
                <span>绑定手机</span>
              </p>
              <span class="h-57 f-12">134*****352</span>
              <span class="b">更换手机</span>
            </li>
            <li>
              <p>
                <i class="iconfont icon-success"></i>
                <span>绑定邮箱</span>
              </p>
              <span class="h-57 f-12">913***@qq.com</span>
              <span class="b">更换邮箱</span>
            </li>
            <li>
              <p>
                <i class="iconfont icon-fail"></i>
                <span>绑定新浪邮箱</span>
              </p>
              <span class="h-57 f-12">未绑定新浪邮箱</span>
              <span class="b">绑定微博</span>
            </li>
            <li>
              <p>
                <i class="iconfont icon-fail"></i>
                <span>绑定QQ账号</span>
              </p>
              <span class="h-57 f-12">未绑定QQ账号</span>
              <span class="b">绑定QQ</span>
            </li>
            <li>
              <p>
                <i class="iconfont icon-success"></i>
                <span>绑定微信账号</span>
              </p>
              <span class="h-57 f-12">飞翔的企鹅</span>
              <span class="b">解绑微信</span>
            </li>
          </ul>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.setting-third{
  background-color: #fff;
}
.person-center {
    width: 860px;
    margin: 0 auto;
    height: 500px;
    .person-center-header{
      display: flex;
      i{
        color: #e4007f;
      }
      p{
        position: relative;
        top: -5px;
        line-height: 20px;
      }
    }
    .third-ul{
      li{
        height: 50px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #b5b5b5;
        span{
          font-size: 12px;
        }
        .icon-success{
          color: #32C864;
        }
        .icon-fail{
          color: #FF9600;
        }
        p{
          display: flex;
          align-items: center;
          height: 16px;
          margin-left: 40px;
          width: 330px;
          i{
            margin-right: 10px;
          }
        }
        .h-57{
          width: 94px;
          margin-right: 242px;
        }
        .b{
          cursor: pointer;
          color: #00a2e6;
        }
      }
    }
}
</style>
